<template>
  <main v-cloak id="content" role="main" class="overflow-hidden">
    <b-overlay :show="showDummy" :opacity="0.85" rounded="lg" no-wrap />
    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden" style="background-color: #F0F8FD;">

      <div class="gradient-y-sm-primary">
        <div class="container space-3">
          <div class="w-md-90 w-lg-65 text-center mx-md-auto mb-9">
            <h2>apidays Hong Kong Blog</h2>
            <p class="lead">Discover the latest news and API tips from the API Community</p>
          </div>

          <div v-for="list in position_list" :id="list.name" :key="list.category">
            <div class="row">
              <div v-for="item in list.position" :key="item.title" class="col-md-6 col-lg-4 mb-5">
                <router-link class="card h-100" :to="{ path: `/blog/${item.url}` }">
                  <div class="card-img-top position-relative">
                    <img class="card-img-top" :src="require('@/assets/img/apidays-2023/images/' + item.image_url)">
                    <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    </figure>
                  </div>
                  <div class="card-body">
                    <div class="mb-3">
                      <h4>{{ item.title }}</h4>
                      <p v-if="item.abstract" class="font-size-1 mb-0">{{ item.abstract }}</p>
                    </div>
                  </div>
                  <div class="card-footer border-0 pt-0">
                    <span class="font-weight-bold">Read More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                  </div>
                </router-link>
              </div>
              <div class="col-md-6 col-lg-4 mb-5">
                <a class="card h-100" href="https://blog.openapihub.com/" target="_blank">
                  <div class="card-img-top position-relative">
                    <img class="card-img-top" src="@/assets/img/apidays-2023/images/oah-blog-cover.jpg">
                    <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    </figure>
                  </div>
                  <div class="card-body">
                    <div class="mb-3">
                      <h4>Unlock More API Blog</h4>
                      <p class="font-size-1 mb-0">Discover a wealth of knowledge in our API community! Explore more blogs to enhance your understanding and stay updated on the latest industry trends.</p>
                    </div>
                  </div>
                  <div class="card-footer border-0 pt-0">
                    <span class="font-weight-bold">Explore More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- ========== End Openings Section ========== -->

    </div>
  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.slick-carousel.js'
import '../../assets/vendor/slick-carousel/slick/slick.js'
import HSVideoPlayer from '../../assets/vendor/hs-video-player/src/js/hs-video-player'
import { mapGetters } from 'vuex'
import { BOverlay } from 'bootstrap-vue'
import debounce from 'lodash/debounce'
export default {
  name: 'Careers',
  components: {
    BOverlay
  },
  data() {
    return {
      position_list: [],
      isLoaded: true
    }
  },
  computed: {
    ...mapGetters([
      'careers', 'showDummy'
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        if (this.$route.hash) {
          this.scrollToSection(this.$route.hash.replace('#', ''))
        }
      }
    }
    if (document.readyState === 'complete') {
      this.$nextTick(debounce(() => {
        if (this.$route.hash) {
          this.scrollToSection(this.$route.hash.replace('#', ''))
        }
      }, 250))
    }
  },
  created() {
    this.position_list = this.$store.getters.careers.position_list
  },
  methods: {
    scrollToList() {
      this.$store.state.careers.scrollToList = true
    },
    scrollToSection(elementID) {
      window.scrollBy({
        top: document.getElementById(elementID).offsetTop - document.querySelector('#header').offsetHeight, // could be negative value
        left: 0
      })
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Careers | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/careers' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Careers | beNovelty' },
        { property: 'og:description', content: 'Be Novelty together! Discover current job openings and join our fun team.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/careers.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/careers' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
